<template>
  <div v-if="observations.length > 0">
    <apexchart
      v-for="(chartSeriesItem, index) in chartSeries"
      :key="index"
      type="rangeBar"
      :height="sparkline ? 125 : chartSplitAmount === 1 ? 140 : chartHeight / chartSplitAmount"
      :series="chartSeriesItem"
      :options="options"
      :style="{ 'margin-top': index > 0 ? '-30px' : null }"
    />
  </div>
</template>

<script>
import { format, isValid, differenceInHours } from 'date-fns'
import chunk from 'chunk-date-range'

export default {
  name: 'ObservationStatusChart',
  props: {
    observedProperty: {
      type: Object,
      required: true
    },

    observations: {
      type: Array,
      required: true
    },

    sparkline: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      chartHeight: window.innerHeight - 180
    }
  },

  computed: {
    chartSplitAmount() {
      let amount = 1

      let startDate = new Date(this.observations[0].observedAt)
      let endDate = new Date(this.observations[this.observations.length - 1].observedAt)

      if (differenceInHours(endDate, startDate) >= 120) {
        amount = 8
      } else if (differenceInHours(endDate, startDate) >= 12) {
        amount = 5
      }

      return amount
    },

    options() {
      return {
        chart: {
          type: 'rangeBar',
          toolbar: {
            show: false
          },
          animations: {
            enabled: this.sparkline
          },

          sparkline: {
            enabled: this.sparkline
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: this.sparkline ? '100%' : '80%',
            rangeBarGroupRows: true,
            rangeBarOverlap: false
          }
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          max: true
        },
        yaxis: {
          show: false
        },
        fill: {
          type: 'solid',
          opacity: 0.6
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'left'
        },
        colors: ['#4CAF50', '#F44336'],
        tooltip: {
          x: {
            formatter: function(val, opts) {
              return isValid(new Date(val)) ? format(val, 'Ppp') : val
            }
          }
        }
      }
    },

    chartSeries() {
      let chartSeries = []

      let observations = JSON.parse(JSON.stringify(this.observations))

      observations.sort((a, b) => {
        return new Date(a.observedAt) - new Date(b.observedAt)
      })

      let intervals = chunk(
        new Date(observations[0].observedAt),
        new Date(observations[observations.length - 1].observedAt),
        this.chartSplitAmount
      )

      let observationsChunks = []

      intervals.forEach((interval, index) => {
        observationsChunks[index] = observations.filter(observation => {
          let observedAt = new Date(observation.observedAt)
          return observedAt >= interval.start && observedAt <= interval.end
        })
      })

      observationsChunks.forEach((observationsChunk, chunkIndex) => {
        let series = [
          {
            name: 'TRUE',
            data: []
          },
          {
            name: 'FALSE',
            data: []
          }
        ]
        if (observationsChunk.length > 0) {
          let startDate = new Date(observationsChunk[0].observedAt).getTime()

          observationsChunk.forEach((observation, index) => {
            if (index > 0) {
              let prevObservation = observationsChunk[index - 1]

              if (observation.result !== prevObservation.result) {
                series[Boolean(prevObservation.result) ? 0 : 1].data.push({
                  x: 'result' + chunkIndex,
                  y: [startDate, new Date(observation.observedAt).getTime()]
                })

                startDate = new Date(observation.observedAt).getTime()
              }

              if (index === observationsChunk.length - 1) {
                series[Boolean(observation.result) ? 0 : 1].data.push({
                  x: 'result' + chunkIndex,
                  y: [startDate, new Date(observation.observedAt).getTime()]
                })

                if (
                  'undefined' !== typeof observationsChunk[chunkIndex + 1] &&
                  observationsChunk[chunkIndex + 1].length > 0
                ) {
                  series[Boolean(observation.result) ? 0 : 1].data.push({
                    x: 'result' + chunkIndex,
                    y: [
                      new Date(observation.observedAt).getTime(),
                      new Date(observationsChunk[chunkIndex + 1][0].observedAt).getTime()
                    ]
                  })
                }
              }
            }
          })
        }

        chartSeries[chunkIndex] = series
      })

      return chartSeries
    }
  },

  watch: {
    windowHeight(newHeight) {
      this.chartHeight = newHeight - 180
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>

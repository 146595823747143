<template>
  <apexchart
    v-if="observations.length > 0"
    :height="sparkline ? 256 : chartHeight"
    :type="sparkline ? 'donut' : 'pie'"
    :series="series"
    :options="options"
  />
  <v-row v-else no-gutters align="center" justify="center" class="fill-height" :style="{ height: chartHeight + 'px' }">
    <div class="text-center">
      <v-icon size="150" color="grey lighten-2">mdi-chart-line</v-icon>
      <div class="headline">
        {{ $t('message.no_observations') }}
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'ObservationPieChart',
  props: {
    observedProperty: {
      type: Object,
      required: true
    },

    observations: {
      type: Array,
      required: true
    },

    sparkline: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      chartHeight: window.innerHeight - 260
    }
  },

  computed: {
    options() {
      if (this.observations.length === 0) {
        return
      }

      let labels = []
      this.observations.forEach(observation => {
        if (!labels.includes(observation.result)) {
          labels.push(observation.result)
        }
      })

      let options = {
        labels: labels,
        chart: {
          animations: {
            enabled: this.sparkline
          },

          sparkline: {
            enabled: this.sparkline
          }
        }
      }

      if (this.sparkline) {
        options = {
          ...options,
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90
            }
          },
          grid: {
            padding: {
              bottom: -128
            }
          }
        }
      }

      return options
    },

    series() {
      if (this.observations.length === 0 || this.options.labels === []) {
        return
      }

      let series = []

      this.options.labels.forEach(label => {
        let sum = 0
        this.observations.forEach(observation => {
          if (observation.result === label) {
            sum++
          }
        })
        series.push(sum)
      })

      return series
    }
  },

  watch: {
    windowHeight(newHeight) {
      this.chartHeight = newHeight - 260
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>
